/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from 'firebase/app';

import { createContext } from 'react';

type FirebaseContextValue = {
  app: firebase.app.App | null;
  auth: firebase.auth.Auth | null;
  db: firebase.firestore.Firestore | null;
};

export const FirebaseContext = createContext<FirebaseContextValue>({
  app: null,
  auth: null,
  db: null,
});

type UserContextValueWithoutUser = {
  signInStatusLoaded: boolean;
  firebaseUser: null;
  firebaseUserDecodedIdToken: null;
};

type UserContextValueWithUser = {
  signInStatusLoaded: true;
  firebaseUser: firebase.User;
  firebaseUserDecodedIdToken: firebase.auth.IdTokenResult;
};

export type UserContextValue =
  | UserContextValueWithoutUser
  | UserContextValueWithUser;

export const UserContext = createContext<UserContextValue>({
  signInStatusLoaded: false,
  firebaseUser: null,
  firebaseUserDecodedIdToken: null,
});
