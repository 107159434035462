import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import { FirebaseContext, UserContext, UserContextValue } from '../../contexts';
// import { isFirebaseTokenInitialized } from './FirebaseUserRole';

const wait = (timeout: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

export function FirebaseApp({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [userContextValue, setUserContextValue] = useState<UserContextValue>({
    signInStatusLoaded: false,
    firebaseUser: null,
    firebaseUserDecodedIdToken: null,
  });
  // const [
  //   credential,
  //   setCredential,
  // ] = useState<firebase.auth.UserCredential | null>(null);
  const app = firebase.app();
  const auth = firebase.auth();
  const db = firebase.firestore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (_firebaseUser) => {
      // コンポーネントロード時（ページのロード時）のログイン状態に応じて非同期に1回呼ばれる（非ログインならnull）
      // その後、ログイン状態の変化ごとに呼ばれる
      // 課金状態をすぐ反映するため、ページロードごとにリフレッシュで対応(将来的には効率化)
      if (_firebaseUser) {
        const decodedToken: firebase.auth.IdTokenResult = await _firebaseUser.getIdTokenResult(true);
        // 本家ではcloud functionsでユーザの初期カスタムクレームがセットされるのを待つ
        // // eslint-disable-next-line no-constant-condition
        // while (true) {
        //   // eslint-disable-next-line no-await-in-loop
        //   decodedToken = await _firebaseUser.getIdTokenResult(true);
        //   if (isFirebaseTokenInitialized(decodedToken)) {
        //     break;
        //   }
        //   // eslint-disable-next-line no-console
        //   console.log('waiting custom claims to be initialized');
        //   // eslint-disable-next-line no-await-in-loop
        //   await wait(3000);
        // }
        setUserContextValue({
          signInStatusLoaded: true,
          firebaseUser: _firebaseUser,
          firebaseUserDecodedIdToken: decodedToken,
        });
      } else {
        setUserContextValue({
          signInStatusLoaded: true,
          firebaseUser: null,
          firebaseUserDecodedIdToken: null,
        });
      }
    });

    return unsubscribe;
  }, [auth]);

  return (
    <FirebaseContext.Provider value={{ app, auth, db }}>
      <UserContext.Provider value={userContextValue}>
        {children}
      </UserContext.Provider>
    </FirebaseContext.Provider>
  );
}
